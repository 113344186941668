import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function HousekeepingIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 34 34" {...props}>
      <g clipPath="url(#clip0_15_1797)">
        <path
          d="M11.9112 27.0718C11.7712 26.1878 11.4357 25.3461 10.9291 24.6082C10.4224 23.8703 9.75746 23.2548 8.98267 22.8066V0H2.97954V22.8052C-2.25925 25.8759 -0.173427 33.9336 5.98243 34H16.9826V32.9999C16.9807 31.57 16.4691 30.1877 15.5395 29.1011C14.61 28.0146 13.3235 27.2951 11.9112 27.0718V27.0718ZM6.98251 2.00016V15.9999H4.98235V2.00016H6.98251ZM4.98235 18.0001H6.98251V22.0847C6.32049 21.9728 5.64438 21.9728 4.98235 22.0847V18.0001ZM5.98243 31.9998C4.92215 31.9984 3.90571 31.5766 3.15604 30.8268C2.40637 30.077 1.98468 29.0605 1.98345 28.0002C2.10497 23.2854 8.59286 22.513 9.85657 27.0001H6.98251V29.0003H10.9828C11.8693 29.0014 12.7304 29.2965 13.4313 29.8393C14.1322 30.382 14.6333 31.1419 14.8563 31.9998H5.98243Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M33.9828 20.0002H31.8811C31.6793 19.0227 31.1903 18.1277 30.4768 17.4297C29.7633 16.7317 28.8578 16.2626 27.8761 16.0823C26.8594 11.9896 21.0774 12.0082 20.0847 16.1042C19.2035 16.3086 18.4175 16.8051 17.8543 17.513C17.2911 18.2208 16.984 19.0984 16.9828 20.0029H14.9827V22.0004H17.202L20.2022 34H28.7633L31.7635 22.0004H33.9828V20.0002ZM20.9825 18.0001H21.9825V17C22.0928 14.3437 25.8733 14.3484 25.9829 17V18.0001H26.9829C27.6026 18.0013 28.2067 18.1942 28.7125 18.5523C29.2182 18.9104 29.6008 19.4162 29.8079 20.0002H18.9836C18.9842 19.47 19.195 18.9617 19.5698 18.5867C19.9447 18.2118 20.4529 18.0008 20.9831 18.0001H20.9825ZM27.2021 31.9998H21.7634L19.2632 21.9997H29.7023L27.2021 31.9998Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M14.9827 13.9998H16.9828V15.9999H14.9827V13.9998Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M29.9824 12.0003H31.9826V13.9998H29.9824V12.0003Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M18.9836 10.0001H20.9825V12.0003H18.9836V10.0001Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1797">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { HousekeepingIcon };
