import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { Add} from "@mui/icons-material";
import React from "react";

import Loader from "../../components/Loader";
import useItinerary from "./itinerary-hook";
import ItineraryTable from "../../components/itinerary/ItineraryTable";
import ItineraryForm from "./itinerary-form";

export default function Itinerary() {
  const {
    activeList,
    closedList,
    loading,
    handleClickEditOpen,
    handleEditClose,
    handleDelete,
    editData,
    setOpen,
    refetchActiveList,
    refetchCloseList,
    open,
    propertyId
  } = useItinerary();

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
          Itinerary Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Itinerary
          </Button>
        </Grid>
        <ItineraryForm
          editData={editData}
          handleEditClose={handleEditClose}
          open={open}
          refetchActiveList={refetchActiveList}
          refetchCloseList={refetchCloseList}
          propertyId={propertyId}
          setOpen={setOpen}
        />
      </Grid>
      {/* <Grid container alignItems={"center"} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight={600}>
            Active Itinerary
          </Typography>
        </Grid>
      </Grid> */}
      <ItineraryTable itineraryList={activeList} handleClickEditOpen={handleClickEditOpen} handleDelete={handleDelete} />
      <Grid container alignItems={"center"} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight={600}>
            Closed Itinerary
          </Typography>
        </Grid>
      </Grid>
      <ItineraryTable itineraryList={closedList} handleClickEditOpen={handleClickEditOpen} handleDelete={handleDelete} />
    </>
  );
}
