import React from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetPromotionQuery,
  useDeletePromotionsMutation,
  Promotion,
  useUpdatePromotionStatusMutation,
} from "../../generated/graphql";

import swal from "sweetalert";

const usePromotions = () => {
  const [editData, setEditData] = React.useState<Promotion | null>(null);

  const [editOpen, setEditOpen] = React.useState(false);

  const handleClickEditOpen = (data: Promotion | null) => {
    setEditData(data);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditData(null);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();


  const [deletePromotion] =
    useDeletePromotionsMutation();
  //Get Promotions
  const {
    data: promotionData,
    loading: loadingPromotion,
    refetch,
  } = useGetPromotionQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const[updateStatus]=useUpdatePromotionStatusMutation()
  const onTogglePrmotion = async (checked: boolean, id: string) => {
    try {
      await updateStatus({
        variables: {
          id: id,
          propertyID: property?.id,
          status: checked,
        },
      });
      swal({
        text: "status change",
        icon: "success",
      });
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deletePromotion({
        variables: {
          id: id,
          propertyID: property.id,
        },
      });

      swal({
        text: "Promotion Deleted Successfully",
        icon: "success",
      });
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    promotionData: promotionData?.getAdminPromotions,
    loadingPromotion,
    setEditOpen,
    refetch,
    handleClickEditOpen,
    handleEditClose,
    editOpen,
    editData,
    handleDelete,
    propertyId: property.id,
    onTogglePrmotion
  };
};

export default usePromotions;
