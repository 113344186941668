import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Button,
  Divider,
  Typography,
  Container,
  styled,
} from "@mui/material";
import useLogin from "./login-hook";

// Login Page style
const LoginItem = styled(Box)(() => ({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "none",
}));

//  background Image section style
const Item = styled(Box)(({ theme }: any) => ({
  backgroundColor: "#fff",
  backgroundPosition: "right",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

// form field style
const FormItem = styled(Box)(() => ({
  textAlign: "center",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { register, handleSubmit, errors, onLogin } = useLogin();

  return (
    <LoginItem>
      <Grid container spacing={0}>
        {/* -----------Login Form ---------------*/}
        <Grid item xs={12} sm={6} md={5}>
          <FormItem>
            <Stack sx={{ px: 4 }}>
              <Container>
                <Typography
                  width={"250px"}
                  src={
                    "https://ennovatech.com/assets/images/company-logo/hotlync.svg"
                  }
                  component={"img"}
                />

                <form onSubmit={handleSubmit(onLogin)}>
                  {/* ----------username------------ */}
                  <TextField
                    {...register("username", {
                      required: "Username is invalid",
                    })}
                    variant="filled"
                    sx={{ mt: 2 }}
                    fullWidth
                    type="text"
                    label="Username"
                    error={errors.username && true}
                    helperText={errors.username?.message}
                  />
                  {/* ------------password------------ */}
                  <TextField
                    autoComplete="password"
                    {...register("password", {
                      required: "Password is invalid",
                    })}
                    error={errors.password && true}
                    helperText={errors.password?.message}
                    variant="filled"
                    sx={{ mt: 2 }}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword((show) => !show)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* ------------Submit------------ */}
                  <Button sx={{ my: 2 }} type="submit" variant="contained">
                    &nbsp;&nbsp;Sign In &nbsp;&nbsp;
                  </Button>
                </form>

                <Divider />
                <Typography color="secondary" component={"p"} fontSize="12px">
                  Copyright © 2022
                  <a href="admin_hotlync" target="_blank" rel="noreferrer">
                    Hotlync
                  </a>
                  . All rights reserved.
                </Typography>
              </Container>
            </Stack>
          </FormItem>
        </Grid>
        {/* ----------- side Box ----------- */}
        <Grid item xs={0} sm={6} md={7}>
          <Item>
            <Box
              sx={{ maxWidth: "100%", height: "auto" }}
              component={"img"}
              src={`https://ennovatech.com/assets/images/cover/cover-aboutUs.svg`}
            ></Box>
          </Item>
        </Grid>
      </Grid>
    </LoginItem>
  );
};

export default Login;
