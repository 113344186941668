import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function JonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="65"
      height="31"
      viewBox="0 0 65 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{width:"50px",marginRight:"6px"}}
    >
      <path
        d="M2.18115 0.496094C2.40088 0.496094 2.58887 0.574219 2.74512 0.730469C2.90137 0.881836 2.97949 1.06494 2.97949 1.27979C2.97949 1.50439 2.90381 1.69238 2.75244 1.84375C2.60596 1.99512 2.42041 2.0708 2.1958 2.0708C1.98584 2.0708 1.80029 1.99268 1.63916 1.83643C1.47803 1.68018 1.39746 1.49463 1.39746 1.27979C1.39746 1.07471 1.47559 0.894043 1.63184 0.737793C1.79297 0.57666 1.97607 0.496094 2.18115 0.496094ZM1.55127 3.33057H2.88428V11.0283C2.88428 12.1953 2.43506 13.0205 1.53662 13.5039L0.547852 12.5298C0.855469 12.4224 1.09961 12.2344 1.28027 11.9658C1.46094 11.6973 1.55127 11.3848 1.55127 11.0283V3.33057Z"
        fill="black"
      />
      <path
        d="M8.08447 3.31592C9.10986 3.31592 9.96191 3.64795 10.6406 4.31201C11.3193 4.97119 11.6587 5.80127 11.6587 6.80225C11.6587 7.77393 11.3145 8.58203 10.626 9.22656C9.9375 9.86621 9.0708 10.186 8.02588 10.186C7.01514 10.186 6.17041 9.86133 5.4917 9.21191C4.81299 8.55762 4.47363 7.74219 4.47363 6.76562C4.47363 5.7793 4.81543 4.95898 5.49902 4.30469C6.1875 3.64551 7.04932 3.31592 8.08447 3.31592ZM8.01123 4.51709C7.37158 4.51709 6.84668 4.72705 6.43652 5.14697C6.02637 5.56689 5.82129 6.10156 5.82129 6.75098C5.82129 7.39551 6.03125 7.92285 6.45117 8.33301C6.87109 8.73828 7.41064 8.94092 8.06982 8.94092C8.72412 8.94092 9.25879 8.73584 9.67383 8.32568C10.0938 7.91064 10.3037 7.38086 10.3037 6.73633C10.3037 6.09668 10.0864 5.56689 9.65186 5.14697C9.21729 4.72705 8.67041 4.51709 8.01123 4.51709Z"
        fill="black"
      />
      <path
        d="M14.4712 3.33057V4.1875C15.0669 3.52832 15.7432 3.19873 16.5 3.19873C16.9199 3.19873 17.3105 3.30859 17.6719 3.52832C18.0332 3.74316 18.3066 4.04102 18.4922 4.42188C18.6826 4.79785 18.7778 5.396 18.7778 6.21631V10.0688H17.4448V6.23096C17.4448 5.54248 17.3398 5.05176 17.1299 4.75879C16.9199 4.46094 16.5684 4.31201 16.0752 4.31201C15.4453 4.31201 14.9106 4.62695 14.4712 5.25684V10.0688H13.1089V3.33057H14.4712Z"
        fill="black"
      />
      <path
        d="M26.3804 6.81689H21.6416C21.6758 7.46143 21.8906 7.97412 22.2861 8.35498C22.6865 8.73584 23.2017 8.92627 23.8315 8.92627C24.7104 8.92627 25.521 8.65283 26.2632 8.10596V9.40967C25.853 9.68311 25.4453 9.87842 25.04 9.99561C24.6396 10.1128 24.1685 10.1714 23.6265 10.1714C22.8843 10.1714 22.2837 10.0176 21.8247 9.70996C21.3657 9.40234 20.9971 8.98975 20.7188 8.47217C20.4453 7.94971 20.3086 7.34668 20.3086 6.66309C20.3086 5.6377 20.5991 4.80518 21.1802 4.16553C21.7612 3.521 22.5156 3.19873 23.4434 3.19873C24.3369 3.19873 25.0498 3.51123 25.582 4.13623C26.1143 4.76123 26.3804 5.59863 26.3804 6.64844V6.81689ZM21.6709 6.01855H25.062C25.0278 5.48633 24.8691 5.07617 24.5859 4.78809C24.3027 4.5 23.9219 4.35596 23.4434 4.35596C22.9648 4.35596 22.5718 4.5 22.2642 4.78809C21.9614 5.07617 21.7637 5.48633 21.6709 6.01855Z"
        fill="black"
      />
      <path
        d="M27.501 9.58545V8.1499C27.877 8.41357 28.2603 8.62842 28.6509 8.79443C29.0464 8.95557 29.3784 9.03613 29.647 9.03613C29.9253 9.03613 30.1646 8.96777 30.3647 8.83105C30.5649 8.69434 30.665 8.53076 30.665 8.34033C30.665 8.14502 30.5991 7.98389 30.4673 7.85693C30.3403 7.7251 30.062 7.53711 29.6323 7.29297C28.7729 6.81445 28.209 6.40674 27.9404 6.06982C27.6768 5.72803 27.5449 5.35693 27.5449 4.95654C27.5449 4.43896 27.7451 4.0166 28.1455 3.68945C28.5508 3.3623 29.0708 3.19873 29.7056 3.19873C30.3647 3.19873 31.041 3.38428 31.7344 3.75537V5.07373C30.9434 4.59521 30.2964 4.35596 29.7935 4.35596C29.5347 4.35596 29.3247 4.41211 29.1636 4.52441C29.0073 4.63184 28.9292 4.77588 28.9292 4.95654C28.9292 5.11279 29 5.26172 29.1416 5.40332C29.2881 5.54492 29.542 5.71582 29.9033 5.91602L30.3794 6.18701C31.5024 6.82178 32.064 7.5249 32.064 8.29639C32.064 8.84814 31.8467 9.30225 31.4121 9.65869C30.9824 10.0103 30.4282 10.186 29.7495 10.186C29.3491 10.186 28.9927 10.1445 28.6802 10.0615C28.3677 9.97363 27.9746 9.81494 27.501 9.58545Z"
        fill="black"
      />
      <path
        d="M0.621094 21.3999L3.12598 18.939V20.3306H5.25732V21.5317H3.12598V24.835C3.12598 25.6064 3.4458 25.9922 4.08545 25.9922C4.56396 25.9922 5.06934 25.8311 5.60156 25.5088V26.7539C5.08887 27.042 4.52979 27.186 3.92432 27.186C3.31396 27.186 2.80615 27.0078 2.40088 26.6514C2.27393 26.5439 2.16895 26.4243 2.08594 26.2925C2.00293 26.1558 1.93213 25.98 1.87354 25.7651C1.81982 25.5454 1.79297 25.1304 1.79297 24.52V21.5317H0.621094V21.3999Z"
        fill="black"
      />
      <path
        d="M6.53174 16.8369H7.86475V21.2168C8.42139 20.5381 9.1123 20.1987 9.9375 20.1987C10.3867 20.1987 10.7896 20.311 11.146 20.5356C11.5024 20.7603 11.7661 21.0703 11.937 21.4658C12.1128 21.8613 12.2007 22.4497 12.2007 23.231V27.0688H10.8677V22.9014C10.8677 22.4082 10.7456 22.0127 10.5015 21.7148C10.2622 21.4121 9.94482 21.2607 9.54932 21.2607C9.25635 21.2607 8.98047 21.3364 8.72168 21.4878C8.46289 21.6392 8.17725 21.8906 7.86475 22.2422V27.0688H6.53174V16.8369Z"
        fill="black"
      />
      <path
        d="M19.8032 23.8169H15.0645C15.0986 24.4614 15.3135 24.9741 15.709 25.355C16.1094 25.7358 16.6245 25.9263 17.2544 25.9263C18.1333 25.9263 18.9438 25.6528 19.686 25.106V26.4097C19.2759 26.6831 18.8682 26.8784 18.4629 26.9956C18.0625 27.1128 17.5913 27.1714 17.0493 27.1714C16.3071 27.1714 15.7065 27.0176 15.2476 26.71C14.7886 26.4023 14.4199 25.9897 14.1416 25.4722C13.8682 24.9497 13.7314 24.3467 13.7314 23.6631C13.7314 22.6377 14.022 21.8052 14.603 21.1655C15.1841 20.521 15.9385 20.1987 16.8662 20.1987C17.7598 20.1987 18.4727 20.5112 19.0049 21.1362C19.5371 21.7612 19.8032 22.5986 19.8032 23.6484V23.8169ZM15.0938 23.0186H18.4849C18.4507 22.4863 18.292 22.0762 18.0088 21.7881C17.7256 21.5 17.3447 21.356 16.8662 21.356C16.3877 21.356 15.9946 21.5 15.687 21.7881C15.3843 22.0762 15.1865 22.4863 15.0938 23.0186Z"
        fill="black"
      />
      <path
        d="M25.084 22.6597C25.084 21.9419 25.3452 21.3755 25.8677 20.9604C26.395 20.5405 27.1128 20.3306 28.021 20.3306H30.7969V21.3706H29.4346C29.6982 21.6392 29.8813 21.8833 29.9839 22.103C30.0864 22.3228 30.1377 22.5742 30.1377 22.8574C30.1377 23.209 30.0376 23.5557 29.8374 23.8975C29.6372 24.2344 29.3784 24.4932 29.061 24.6738C28.7485 24.8545 28.2334 24.9985 27.5156 25.106C27.0127 25.1792 26.7612 25.3525 26.7612 25.626C26.7612 25.7822 26.854 25.9116 27.0396 26.0142C27.23 26.1118 27.5718 26.2144 28.0649 26.3218C28.8901 26.5024 29.4199 26.644 29.6543 26.7466C29.8936 26.8491 30.1084 26.9956 30.2988 27.186C30.6211 27.5083 30.7822 27.9136 30.7822 28.4019C30.7822 29.0415 30.4966 29.5518 29.9253 29.9326C29.3589 30.3135 28.5996 30.5039 27.6475 30.5039C26.6855 30.5039 25.9165 30.311 25.3403 29.9253C24.769 29.5444 24.4834 29.0317 24.4834 28.3872C24.4834 27.4741 25.0474 26.8857 26.1753 26.6221C25.7261 26.334 25.5015 26.0483 25.5015 25.7651C25.5015 25.5503 25.5967 25.355 25.7871 25.1792C25.9824 25.0034 26.2437 24.874 26.5708 24.791C25.5796 24.3516 25.084 23.6411 25.084 22.6597ZM27.5889 21.4878C27.2275 21.4878 26.9199 21.6099 26.666 21.854C26.4121 22.0981 26.2852 22.3911 26.2852 22.7329C26.2852 23.0796 26.4097 23.3677 26.6587 23.5972C26.9077 23.8218 27.2227 23.9341 27.6035 23.9341C27.9795 23.9341 28.292 23.8193 28.541 23.5898C28.7949 23.3555 28.9219 23.0649 28.9219 22.7183C28.9219 22.3667 28.7949 22.0737 28.541 21.8394C28.2871 21.605 27.9697 21.4878 27.5889 21.4878ZM27.2739 27.3838C26.8247 27.3838 26.4561 27.479 26.168 27.6694C25.8848 27.8599 25.7432 28.104 25.7432 28.4019C25.7432 29.0952 26.3682 29.4419 27.6182 29.4419C28.209 29.4419 28.6655 29.354 28.9878 29.1782C29.3149 29.0073 29.4785 28.7632 29.4785 28.4458C29.4785 28.1333 29.2734 27.877 28.8633 27.6768C28.4531 27.4814 27.9233 27.3838 27.2739 27.3838Z"
        fill="black"
      />
      <path
        d="M33.2578 20.3306V21.876L33.3311 21.7588C33.9756 20.7188 34.6201 20.1987 35.2646 20.1987C35.7676 20.1987 36.2925 20.4526 36.8394 20.9604L36.1362 22.1323C35.6724 21.6929 35.2427 21.4731 34.8472 21.4731C34.4175 21.4731 34.0439 21.6782 33.7266 22.0884C33.4141 22.4985 33.2578 22.9844 33.2578 23.5459V27.0688H31.9175V20.3306H33.2578Z"
        fill="black"
      />
      <path
        d="M40.6187 20.3159C41.644 20.3159 42.4961 20.6479 43.1748 21.312C43.8535 21.9712 44.1929 22.8013 44.1929 23.8022C44.1929 24.7739 43.8486 25.582 43.1602 26.2266C42.4717 26.8662 41.605 27.186 40.5601 27.186C39.5493 27.186 38.7046 26.8613 38.0259 26.2119C37.3472 25.5576 37.0078 24.7422 37.0078 23.7656C37.0078 22.7793 37.3496 21.959 38.0332 21.3047C38.7217 20.6455 39.5835 20.3159 40.6187 20.3159ZM40.5454 21.5171C39.9058 21.5171 39.3809 21.7271 38.9707 22.147C38.5605 22.5669 38.3555 23.1016 38.3555 23.751C38.3555 24.3955 38.5654 24.9229 38.9854 25.333C39.4053 25.7383 39.9448 25.9409 40.604 25.9409C41.2583 25.9409 41.793 25.7358 42.208 25.3257C42.6279 24.9106 42.8379 24.3809 42.8379 23.7363C42.8379 23.0967 42.6206 22.5669 42.186 22.147C41.7515 21.7271 41.2046 21.5171 40.5454 21.5171Z"
        fill="black"
      />
      <path
        d="M50.7261 25.4941V26.8125C50.0571 27.0615 49.4028 27.186 48.7632 27.186C47.7085 27.186 46.8662 26.8735 46.2363 26.2485C45.6113 25.6235 45.2988 24.7861 45.2988 23.7363C45.2988 22.6768 45.604 21.8223 46.2144 21.1729C46.8247 20.5234 47.6279 20.1987 48.624 20.1987C48.9707 20.1987 49.2808 20.2329 49.5542 20.3013C49.8325 20.3647 50.1743 20.4868 50.5796 20.6675V22.0884C49.9058 21.6587 49.2808 21.4438 48.7046 21.4438C48.104 21.4438 47.6108 21.6562 47.2251 22.0811C46.8394 22.501 46.6465 23.0381 46.6465 23.6924C46.6465 24.3809 46.854 24.9277 47.269 25.333C47.689 25.7383 48.2529 25.9409 48.9609 25.9409C49.4736 25.9409 50.062 25.792 50.7261 25.4941Z"
        fill="black"
      />
      <path
        d="M57.9917 23.8169H53.2529C53.2871 24.4614 53.502 24.9741 53.8975 25.355C54.2979 25.7358 54.813 25.9263 55.4429 25.9263C56.3218 25.9263 57.1323 25.6528 57.8745 25.106V26.4097C57.4644 26.6831 57.0566 26.8784 56.6514 26.9956C56.251 27.1128 55.7798 27.1714 55.2378 27.1714C54.4956 27.1714 53.895 27.0176 53.436 26.71C52.9771 26.4023 52.6084 25.9897 52.3301 25.4722C52.0566 24.9497 51.9199 24.3467 51.9199 23.6631C51.9199 22.6377 52.2104 21.8052 52.7915 21.1655C53.3726 20.521 54.127 20.1987 55.0547 20.1987C55.9482 20.1987 56.6611 20.5112 57.1934 21.1362C57.7256 21.7612 57.9917 22.5986 57.9917 23.6484V23.8169ZM53.2822 23.0186H56.6733C56.6392 22.4863 56.4805 22.0762 56.1973 21.7881C55.9141 21.5 55.5332 21.356 55.0547 21.356C54.5762 21.356 54.1831 21.5 53.8755 21.7881C53.5728 22.0762 53.375 22.4863 53.2822 23.0186Z"
        fill="black"
      />
      <path
        d="M60.8701 20.3306V21.876L60.9434 21.7588C61.5879 20.7188 62.2324 20.1987 62.877 20.1987C63.3799 20.1987 63.9048 20.4526 64.4517 20.9604L63.7485 22.1323C63.2847 21.6929 62.855 21.4731 62.4595 21.4731C62.0298 21.4731 61.6562 21.6782 61.3389 22.0884C61.0264 22.4985 60.8701 22.9844 60.8701 23.5459V27.0688H59.5298V20.3306H60.8701Z"
        fill="black"
      />
    </SvgIcon>
  );
}

export { JonesIcon };
