import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function FishIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgIcon"
    >
      <g clipPath="url(#clip0_37_92)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#F68B5C"
        />
        <path
          d="M71.12 50C72.99 50.27 74.86 50.48 76.71 50.8C80.4247 51.4746 84.0534 52.5578 87.53 54.03C88.13 54.28 88.19 54.63 88.1 55.16C86.7751 63.3863 82.9019 70.9893 77.0262 76.8972C71.1506 82.8052 63.5689 86.7201 55.35 88.09C55.201 88.1399 55.0432 88.1576 54.8868 88.142C54.7305 88.1263 54.5793 88.0776 54.4432 87.9992C54.307 87.9207 54.1891 87.8142 54.0972 87.6868C54.0053 87.5594 53.9415 87.4139 53.91 87.26C51.8944 82.4181 50.6363 77.2948 50.18 72.07C50.139 71.8158 50.0788 71.5651 50 71.32V64.47C50.0883 64.1622 50.1519 63.8479 50.19 63.53C50.4818 60.1016 51.1973 56.7225 52.32 53.47C52.3902 53.1908 52.5354 52.9362 52.7399 52.7335C52.9443 52.5308 53.2002 52.3878 53.48 52.32C55.39 51.83 57.28 51.23 59.21 50.81C60.94 50.44 62.72 50.26 64.47 50H71.12ZM65.12 69.15L69.12 65.15L65.03 61.07L61.08 65.02L65.12 69.15Z"
          fill="black"
        />
        <path
          d="M123.88 124.13C120.3 124.13 116.78 124.33 113.27 124.23C106.105 124.088 98.9839 123.08 92.0605 121.23C84.0005 118.99 76.5205 115.6 70.2305 109.96C65.1602 105.397 60.8258 100.077 57.3805 94.19C57.3205 94.08 57.2805 93.96 57.2305 93.86C76.9305 89.15 89.0905 76.97 93.8405 57.17C95.3805 58.17 96.9705 59.17 98.4705 60.17C106.07 65.58 112.64 71.94 116.81 80.47C119.801 86.6869 121.869 93.3065 122.95 100.12C124.17 107.419 124.559 114.833 124.11 122.22C124.11 122.52 124.11 122.81 124.04 123.1C123.97 123.39 123.96 123.61 123.88 124.13ZM75.5705 97.13L78.1605 102.37C88.6569 97.1694 97.1529 88.6628 102.34 78.16L97.1105 75.61C92.4773 84.9412 84.9103 92.4942 75.5705 97.11V97.13Z"
          fill="black"
        />
        <path
          d="M122.1 150C119.014 143.46 115.88 136.82 112.7 130.08C113.23 130.08 113.61 130.08 113.98 130.08C118.767 130.19 123.556 129.96 128.31 129.39C129.09 129.3 129.31 128.95 129.4 128.23C129.983 123.413 130.207 118.56 130.07 113.71C130.07 113.43 130.07 113.14 130.07 112.71L150 122.11L122.1 150Z"
          fill="black"
        />
        <path
          d="M118.75 72.17L138.08 79.17L126.8 90.43C125.064 83.9683 122.349 77.8103 118.75 72.17Z"
          fill="black"
        />
        <path
          d="M72.1797 118.77C77.7619 122.339 83.8551 125.036 90.2497 126.77L79.0497 137.93C76.8497 131.783 74.5597 125.397 72.1797 118.77Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_92">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { FishIcon };
