import {
  Grid,
  Typography,
  Button,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import React from "react";

import ReportTable from "../../components/report/ReportTable";

import { DatePicker } from "@mui/x-date-pickers";
import Loader from "../../components/Loader";
import useReports from "./report-hook";

export enum TypesOfTimePeriod {
  Today = "Today",
  Yesterday = "Yesterday",
  Last7Days = "Last 7 Days",
  Last30Days = "Last 30 Days",
  Custom = "Custom",
}

export default function Reports() {
  const {
    row,
    loadingOrders,
    timePeriod,
    setTimePeriod,
    setStartDate,
    setEndDate,
  } = useReports();

  if (loadingOrders) {
    return <Loader />;
  }
  return (
    <>
      <Grid container alignItems={"center"} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight={600}>
            Reports
          </Typography>
        </Grid>
        {timePeriod !== TypesOfTimePeriod.Custom ? (
          <Grid
            item
            xs={2}
            display={"flex"}
            justifyContent="center"
            justifySelf={"flex-start"}
          >
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                style={{ backgroundColor: "white", padding: "0 5px" }}
              >
                Filter
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={({ target }: any) => {
                  setTimePeriod(target.value || "");
                }}
                label="Section"
                value={timePeriod}
              >
                {Object.values(TypesOfTimePeriod)?.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={6} display="flex" alignItems="center">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={3}
            >
              <Typography pr={1} fontWeight={600}>
                From
              </Typography>
              <DatePicker
                disableFuture={true}
                onChange={(newValue: Date | null) =>
                  setStartDate(
                    newValue !== null ? newValue.toISOString() : ""
                  )
                }
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <Typography pr={1} fontWeight={600}>
                To
              </Typography>
              <DatePicker
                disableFuture={true}
                onChange={(newValue: Date | null) =>
                  setEndDate(
                    newValue !== null ? newValue.toISOString() : ""
                  )
                }
              />
            </Box>
            <Box textAlign={"center"}>
              <Button
                type="button"
                variant="contained"
                onClick={() => setTimePeriod(TypesOfTimePeriod.Today)}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <ReportTable orderRow={row} />
    </>
  );
}
