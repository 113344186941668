import { useEffect, useState } from "react";
import { Hub } from "aws-amplify";

import { AUTH_KEY } from "../utils/constant";
import client from "./apolloClient";
import meQuery from "../utils/meQuery";
import { useAuthProvider } from "./authContext";

const useUserInfo = () => {
  const { getUser, setUser } = useAuthProvider();
  const [user, setNewUser] = useState(getUser());
  const [isLoadingUserInfo, setLoadingUserInfo] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        setLoadingUserInfo(true);
        const { data } = await client.query({
            query: meQuery,
            variables: {
                domain: process.env.REACT_APP_PROPERTYID,
              },
            fetchPolicy: 'network-only',

          });
          const currentUser = data?.getPropertyByDomain;
          setUser(currentUser);
          setNewUser(currentUser);
      } catch (error) {
        console.log(error);
      }
      setLoadingUserInfo(false);
    };

    const onAuthChange = async ({ payload: { event } }: any) => {
      switch (event) {
        case "signIn":
          await getUserInfo();
          break;
        case "signOut":
          setUser(null);
          setNewUser(null);
          break;
      }
    };

    Hub.listen(AUTH_KEY, onAuthChange);
    return () => {
      Hub.remove(AUTH_KEY, onAuthChange);
    };
  }, [setUser]);
  return [isLoadingUserInfo, user];
};

export default useUserInfo;
