import { Add } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import useEdible from "./hotel-edible-hook";
import Loader from "../../components/Loader";
import DineInMenuForm from "./Dine-In-Menu-form";
import useCategory from "../Dine-In-Categories/dine-in-category-hooks";
import useSection from "../Sections/dine-in-section-hook";
import useAllergen from "../Allregens/allergen-hooks";
import { groupBy } from "lodash";

import DraggableItem from "../../components/DraggableItem";

const HotelEdible = () => {
  const {
    edibleItems,
    loadingEdibleItems,
    handleClickEditOpen,
    handleEditClose,
    handleDelete,
    editData,
    open,
    property,
    setOpen,
    refetchItems,
    onSave,
    onToggle,
    handleSyncPOSMenu,
  } = useEdible();
  const { Categories } = useCategory();
  const { Sections } = useSection();
  const { Allergens } = useAllergen();

  const groupedMenu = groupBy(edibleItems, "type");

  if (loadingEdibleItems) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Edibles Item
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          {
            property.has_simphony_pos ? <Button
                size="large"
                disableElevation={false}
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleSyncPOSMenu()}
                sx={{ mr: 1 }}
            >
              Sync POS Menu
            </Button>:<Button
                size="large"
                disableElevation={false}
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleClickEditOpen(null)}
                sx={{ mr: 1 }}
            >
              Add Item
            </Button>
          }

        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {Object.keys(groupedMenu)
          .sort()
          .map((key) => {
            return (
              <>
                <Typography
                  variant="h6"
                  style={{
                    marginLeft: "40px",
                    fontWeight: 600,
                    marginTop: "30px",
                  }}
                >
                  {key}
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  style={{ marginLeft: "12px" }}
                >
                  <DraggableItem
                    itemArray={groupedMenu[key]}
                    handleDelete={handleDelete}
                    handleClickEditOpen={handleClickEditOpen}
                    onToggle={onToggle}
                    onSave={onSave}
                  />
                </Grid>
              </>
            );
          })}
        {/* Edit Dialog */}
        <DineInMenuForm
          allergens={Allergens}
          categories={Categories}
          sections={Sections}
          editData={editData}
          handleEditClose={handleEditClose}
          open={open}
          propertyId={property.id}
          refetchItems={refetchItems}
          setOpen={setOpen}
        />
      </Grid>
    </>
  );
};

export default HotelEdible;
