import gql from "graphql-tag";

const query = gql`
  query Me($domain: String!) {
    getPropertyByDomain(domain: $domain) {
      id
      img
      name
      primary_color
      primary_light
      primary_text_color
      secondary_text_color
      domain
      hotlync_api_url
      chat_api_url
      has_simphony_pos
      simphony_pos_url
      direct_simphony_pos_check
      pos_loc_ref
      pos_rvc_ref
    }
  }
`;

export default query;
