import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function AlcoholIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 27 34" {...props}>
      <g clipPath="url(#clip0_15_1853)">
        <path
          d="M13.9228 16.4475C12.2802 14.0143 11.4048 11.1532 11.4077 8.22707V5.97656H12.4142V3.98438H11.4077V0H5.36819V3.98438H4.36161V5.97656H5.36819V8.22707C5.371 11.1532 4.49563 14.0143 2.85308 16.4475C0.989493 19.2076 -0.00360838 22.4534 -0.000235578 25.7729V34H16.7761V25.7729C16.7795 22.4534 15.7864 19.2076 13.9228 16.4475ZM7.38134 1.99219H9.3945V3.98438H7.38134V1.99219ZM7.38134 8.22707V5.97656H9.3945V8.22707C9.39149 11.2678 10.2247 14.252 11.8049 16.8599L8.38792 20.24L4.97092 16.8586C6.55088 14.2511 7.38411 11.2674 7.38134 8.22707ZM14.7629 32.0078H2.01292V25.7729C2.01074 23.2638 2.65462 20.7956 3.88382 18.6011L8.38792 23.0569L12.892 18.6004C14.1211 20.7949 14.765 23.2632 14.7629 25.7723V32.0078Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M8.38815 24.0391C7.7909 24.0391 7.20706 24.2143 6.71047 24.5427C6.21388 24.871 5.82683 25.3377 5.59827 25.8838C5.36972 26.4298 5.30992 27.0307 5.42643 27.6103C5.54295 28.19 5.83055 28.7225 6.25287 29.1404C6.67519 29.5583 7.21325 29.8429 7.79902 29.9582C8.3848 30.0735 8.99196 30.0143 9.54375 29.7882C10.0955 29.562 10.5672 29.179 10.899 28.6875C11.2308 28.1961 11.4079 27.6184 11.4079 27.0273C11.407 26.2351 11.0886 25.4755 10.5224 24.9153C9.95632 24.3551 9.18876 24.0399 8.38815 24.0391ZM8.38815 28.0234C8.18906 28.0234 7.99445 27.965 7.82892 27.8556C7.66339 27.7461 7.53437 27.5905 7.45819 27.4085C7.382 27.2265 7.36207 27.0262 7.40091 26.833C7.43975 26.6398 7.53561 26.4623 7.67639 26.323C7.81716 26.1837 7.99651 26.0888 8.19177 26.0504C8.38703 26.012 8.58942 26.0317 8.77335 26.1071C8.95728 26.1825 9.11448 26.3101 9.22509 26.4739C9.33569 26.6378 9.39472 26.8303 9.39472 27.0273C9.39472 27.2915 9.28867 27.5449 9.0999 27.7317C8.91113 27.9185 8.65511 28.0234 8.38815 28.0234Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M26.8422 23.3086V12.0195H18.7896V23.3086C18.7906 24.1917 19.0876 25.0495 19.6341 25.7477C20.1805 26.4458 20.9455 26.9449 21.8093 27.1668V32.0078H18.7896V34H26.8422V32.0078H23.8224V27.1668C24.6862 26.9449 25.4512 26.4458 25.9977 25.7477C26.5441 25.0495 26.8411 24.1917 26.8422 23.3086ZM20.8027 14.0117H24.829V15.3398H20.8027V14.0117ZM22.8159 25.3008C22.2819 25.3008 21.7699 25.0909 21.3923 24.7173C21.0148 24.3437 20.8027 23.837 20.8027 23.3086V17.332H24.829V23.3086C24.829 23.837 24.6169 24.3437 24.2394 24.7173C23.8618 25.0909 23.3498 25.3008 22.8159 25.3008Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1853">
          <rect width="27" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { AlcoholIcon };
