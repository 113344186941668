import React, { useEffect } from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetHotelServiceQuery,
  HotelService,
  useAddServicesMutation,
  useGetUploadSignedUrlLazyQuery,
  SubService,
  HotelServiceInput,
} from "../../generated/graphql";
import { LexicalEditor } from "lexical";
import {$generateHtmlFromNodes, $generateNodesFromDOM} from '@lexical/html';
import {$getRoot, $insertNodes} from 'lexical';

import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const useEditServices = (id: string|undefined) => {
  const navigate = useNavigate();
    const { getUser } = useAuthProvider();
  
    const property = getUser();
  
    const {
      data: serviceData,
      // loading: loadingService,
      // refetch,
    } = useGetHotelServiceQuery({
      fetchPolicy: "network-only",
      variables: {
        propertyID: property?.id,
        id: id || "",
      },
      skip: !id
    });

    const [ssEditData, setSSEditData] = React.useState<SubService | null | undefined>(null);    
    const [editData, setEditData] = React.useState<HotelService | HotelServiceInput | null | undefined>(null);
    
    const [index, setIndex] = React.useState<number | null>(null);
    
    useEffect(()=>{
        if(!serviceData?.getHotelService){
            return
        }
        // clean the payload of __typename
        const cleanPayload = JSON.parse(JSON.stringify(serviceData?.getHotelService, (name, val) => {
          if (name === '__typename') {
              delete val[name];
          } else {
              return val;
          }
        }));
        setEditData(cleanPayload);
    },[serviceData?.getHotelService])

    const [image, setImage] = React.useState<any>("");
    const [showForm, setShowForm] = React.useState(false);
    const [getUploadSignedURL, { loading: loadingImage }] =
      useGetUploadSignedUrlLazyQuery({
        fetchPolicy: "network-only",
      });

    const [upsertHotelService] =
      useAddServicesMutation();

  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = property?.id;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setSSEditData({...ssEditData,img:uploadedImageURL,name:ssEditData?.name||""})
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };
  // Add Service Details
  const onSubmit = async () => {
    if(!editData || !editData?.name){
        return
    }
    try {
      const res = await upsertHotelService({
        variables: {
          hotelServicesInput: {
            id: editData?.id || "",
            name: editData?.name,
            icon: editData?.icon,
            sub_services: editData?.sub_services,
            property_id: property?.id,
          },
        },
      });
      if (res.data?.upsertHotelService) {
        navigate('/hotel-services')
        setImage("")
        swal({
          text: "Service Added Successfully",
          icon: "success",
        });
      }
    //   refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  
    const onInitialEditorState = (
      editor: LexicalEditor
        )=>{
        let htmlString = ssEditData?.desc || ""
        if (!htmlString.startsWith("<")) {
            htmlString = `<p>${htmlString}</p>`
        }
        const parser = new DOMParser();
        const dom = parser.parseFromString(htmlString, "text/html");
        
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        $insertNodes(nodes);
    };

    const onFormattingChange = 
        (editorState:string,editorInstance?: LexicalEditor) => {
          if (editorInstance && ssEditData?.name) {
            editorInstance.update(() => {
              const htmlString = $generateHtmlFromNodes(editorInstance, null);
              setSSEditData({...ssEditData,desc: htmlString})
            });
          }
      };

    const handleSSEdit = (index:number) => {
      if(!editData?.sub_services){
        return
      }
      const ss = editData?.sub_services[index];
      setSSEditData(ss);
      setIndex(index);
      setShowForm(true);
    }

    const handleSSDelete = (index:number) => {
      if(!editData?.sub_services){
        return
      }
      const ssList = [...editData?.sub_services];
      ssList.splice(index,1)
      setEditData({...editData,sub_services:ssList})
    }

    const handleAddOrUpdate = () => {
      if(!ssEditData || !editData){
        return
      }
      let ssList
      if(index===null){
        ssList = [...(editData?.sub_services || []),ssEditData]
      }else{
        ssList = [...(editData?.sub_services || [])]
        ssList[index] = ssEditData
      }
      setEditData({...editData,sub_services:ssList})
      setShowForm(false);
      setSSEditData(null);
      setIndex(null);
      setImage(null);
    }
  
    return {
      // serviceData,
      // loading:loadingService,
      // refetch,
      editData,
      propertyId: property.id,
      setEditData,
      onInitialEditorState,
      onFormattingChange,
      handleImage,
      loadingImage,
      image,
      setImage,
      onSubmit,
      showForm,
      setShowForm,
      handleSSEdit,
      ssEditData,
      setSSEditData,
      handleAddOrUpdate,
      setIndex,
      index,
      handleSSDelete
    };
  };
  
  export default useEditServices;
  