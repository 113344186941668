import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function ShelfishIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgIcon"
    >
      <g clipPath="url(#clip0_37_134)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#BD9FCB"
        />
        <path
          d="M47.3301 59.46C47.4701 58.98 47.6201 58.46 47.7601 58.02C48.4183 55.7694 49.7704 53.7842 51.6237 52.3476C53.4769 50.911 55.7365 50.0964 58.0801 50.02C73.8467 50.02 89.6168 50.02 105.39 50.02H105.88V56.16H59.0402C56.1602 56.16 54.1902 57.62 53.6602 60.1C53.5033 60.8042 53.5044 61.5344 53.6636 62.2381C53.8228 62.9417 54.136 63.6014 54.5807 64.1694C55.0254 64.7375 55.5906 65.1999 56.2355 65.5234C56.8803 65.8468 57.5889 66.0233 58.3102 66.04C61.7402 66.09 65.1701 66.04 68.6001 66.04C75.1801 66.04 81.7601 66.04 88.3401 66.04C88.6554 66.0101 88.9726 66.0738 89.2518 66.2232C89.5311 66.3726 89.7601 66.6011 89.9102 66.88C94.4017 73.4475 96.3691 81.4167 95.4502 89.32C94.6505 95.3842 92.3964 101.165 88.8801 106.17C88.7431 106.325 88.5757 106.451 88.3882 106.538C88.2007 106.626 87.9971 106.674 87.7902 106.68C82.3148 106.801 76.9179 105.362 72.2301 102.53C64.6101 98.18 59.8902 91.53 57.4302 83.24C57.2802 82.73 57.1102 82.24 56.9402 81.67H47.3301V75.51H55.8901C55.8201 74.51 55.7201 73.65 55.7101 72.8C55.7343 72.516 55.6513 72.2331 55.4773 72.0073C55.3033 71.7814 55.051 71.629 54.7701 71.58C52.4562 70.8662 50.4915 69.3151 49.2601 67.23C48.5365 65.7814 47.9052 64.2884 47.3701 62.76L47.3301 59.46ZM84.9302 76.54H78.8901V82.49H84.8901L84.9302 76.54Z"
          fill="black"
        />
        <path
          d="M121.5 130.41C123.814 129.525 125.797 127.945 127.177 125.888C128.558 123.831 129.268 121.397 129.21 118.92C129.16 118.53 129.255 118.135 129.476 117.809C129.697 117.483 130.029 117.249 130.41 117.15C135.434 115.086 139.919 111.901 143.524 107.839C147.129 103.776 149.758 98.9437 151.21 93.71C151.269 93.4798 151.398 93.2737 151.58 93.12C151.74 93.81 151.91 94.5 152.05 95.19C152.19 95.88 152.34 96.67 152.42 97.41C153.47 106 151.378 114.68 146.529 121.848C141.681 129.016 134.403 134.188 126.04 136.41C124.916 136.705 123.778 136.938 122.63 137.11C122.333 137.125 122.053 137.25 121.843 137.461C121.634 137.672 121.512 137.953 121.5 138.25C120.34 144.25 116.79 148.14 110.83 149.69C109.899 149.91 108.946 150.021 107.99 150.02C103.15 150.02 98.3196 150.02 93.4896 150.02H92.8696C92.3496 144.14 92.8697 138.64 97.9497 134.53C92.8697 130.45 92.3496 124.95 92.8696 118.99H93.8896C98.2096 118.99 102.53 119.1 106.89 118.99C110.289 118.829 113.633 119.893 116.314 121.988C118.995 124.084 120.835 127.072 121.5 130.41Z"
          fill="black"
        />
        <path
          d="M96.6199 66.06H97.8899C103.82 66.06 109.75 66.06 115.69 66.06C117.12 66.06 118.56 66.15 120 66.28C120.166 66.3262 120.319 66.4122 120.444 66.5307C120.57 66.6493 120.664 66.7966 120.72 66.96C122.41 76.1381 121.57 85.6031 118.29 94.34C116.628 98.6593 114.263 102.674 111.29 106.22C111.048 106.49 110.711 106.655 110.35 106.68C105.69 106.68 101.02 106.68 96.3499 106.68C96.191 106.658 96.0339 106.625 95.8799 106.58C99.5052 100.461 101.534 93.5283 101.78 86.42C101.921 79.2929 100.138 72.2596 96.6199 66.06Z"
          fill="black"
        />
        <path
          d="M127.1 67.43C129.23 68.33 131.2 69.03 133.1 69.96C138.375 72.5813 142.89 76.5135 146.21 81.38C146.599 82.1195 146.756 82.9596 146.66 83.79C146.492 89.0442 145.084 94.1843 142.55 98.79C139.607 103.947 135.173 108.092 129.83 110.68C129.01 111.1 128.16 111.46 127.32 111.85C127.23 111.865 127.139 111.865 127.05 111.85C126.062 110.488 124.814 109.337 123.376 108.463C121.939 107.589 120.343 107.01 118.68 106.76C126.7 94.83 128.89 81.68 127.1 67.43Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_134">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { ShelfishIcon };
