import React from "react";
import { EggIcon } from "../icons/EggIcon";
import { FishIcon } from "../icons/FishIcon";
import { MilkIcon } from "../icons/MilkIcon";
import { PeanutsIcon } from "../icons/PeanutsIcon";
import { ShelfishIcon } from "../icons/Shelfishicon";
import { SoybeansIcon } from "../icons/SoybeansIcon";
import { TreenutsIcon } from "../icons/TreenutsIcon";
import { WheatIcon } from "../icons/WheatIcon";

export const ALLERGEN_ICONS = {
  SoybeansIcon: <SoybeansIcon />,
  PeanutsIcon: <PeanutsIcon />,
  WheatIcon: <WheatIcon />,
  TreenutsIcon: <TreenutsIcon />,
  ShelfishIcon: <ShelfishIcon />,
  MilkIcon: <MilkIcon />,
  FishIcon: <FishIcon />,
  EggIcon: <EggIcon />,
};
export const ALLERGEN_NAMES = {
  SoybeansIcon: "soybean",
  PeanutsIcon: "peanuts" ,
  WheatIcon: "wheat",
  TreenutsIcon: "treenuts",
  ShelfishIcon:  "shelfish",
  MilkIcon:"milk",
  FishIcon: "fish",
  EggIcon: "egg" ,
};
