import { Add, EditOutlined } from "@mui/icons-material";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import useCategory from "./dine-in-category-hooks";
import CategoryForm from "./Dine-In-Categories-form";
import Loader from "../../components/Loader";
import { Draggable, useDraggable,  useDraggableContext,
  moveItems, } from "react-tiny-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useAutoScroll } from "react-tiny-autoscroll";
import UiSwitch from "../../components/toggleButton/UiSwitch";

const Item = ({
  id,
  name,
  sequence,
  desc,
  listeners,
  isDragging,
  handleDelete,
  handleClickEditOpen,
  is_Activated,
  onToggle
}: {
  id: any;
  name: any;
  sequence: any;
  desc: any;
  listeners: any;
  isDragging: any;
  handleDelete: any;
  handleClickEditOpen: any;
  is_Activated:boolean;
  onToggle:any
  
}) => {
  const index = Number(id);
  const opacity = isDragging ? 0.5 : 1;

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, opacity, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {sequence}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {name}
      </div>
      <div
        style={{
          display: "flex",
          flex: 3,
          alignItems: "center",
        }}
      >
        {desc}
      </div>
      <UiSwitch 
        checked={is_Activated != null ? is_Activated : true}
        onChange={() => onToggle()}
        label=""
      />
      <IconButton
        onClick={() => handleClickEditOpen()}
        color="info"
        aria-label="edit"
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        onClick={() => handleDelete()}
        color="error"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
      <div className="dnd-icon" {...listeners}>
        <DragIndicatorIcon />
      </div>
    </Box>
  );
};

const DraggableItem = ({
  index,
  context,
  item,
  handleDelete,
  handleClickEditOpen,
  onToggle
}: {
  index: any;
  context: any;
  item: any;
  handleDelete: any;
  handleClickEditOpen: any;
  onToggle: any
}) => {
  const {
    listeners, // Handler listeners can be passed to Draggable component as well
    isDragging,
  } = useDraggable(context, index);

  return (
    <Draggable context={context} key={item.id} index={index}>
      <Item
        id={item.id}
        sequence={index + 1}
        name={item.name}
        desc={item.desc}
        listeners={listeners}
        isDragging={isDragging}
        is_Activated={item.is_Activated}
        handleDelete={() => {
          handleDelete?.(item.id);
        }}
        handleClickEditOpen={() => {
          handleClickEditOpen?.(item);
        }}
        onToggle={() =>
          onToggle(
            item
          )
        }
      />
    </Draggable>
  );
};

const HotelCategories = () => {
  const {
    Categories,
    handleClickEditOpen,
    editData,
    handleEditClose,
    open,
    propertyId,
    refetchCategory,
    setOpen,
    loadingCategories,
    handleDelete,
    onSave,
    onToggleCategories
    
  } = useCategory();

  const [items, setItems] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (Categories) {
      setItems(
        Categories?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
      );
    }
  }, [Categories]);

  const onDrop = async (dragIndex: number, overIndex: number) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
    nextItems.filter((item, i) => onSave({ ...item, sequence: i }));
  };

  const context = useDraggableContext({
    onDrop,
  });
  const containerRef = React.useRef<HTMLDivElement>(null);
  useAutoScroll({ containerRef, skip: !context.isDragging });
  if (loadingCategories) {
    return <Loader />;
  }

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Category Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Category
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
      <Box
          ref={containerRef}
          sx={{
            border: "1px solid #ddd",
            overflowY: "auto",
            borderRadius: "4px",
            m: 2,
            width: "100%",
          }}
        >
          <Box
           sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            borderBottom: "1px solid #eee",
          }}
          style={{ height:'initial', opacity: 1, backgroundColor: "white" }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Sequence"}
            </div>
            <div
              style={{
                display: "flex",
                flex: "2 0 7%",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Category"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 2.5,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Description"}
            </div>
            <div     
                style={{
                display: "flex",
                flex: '0.5',
                alignItems: "center",
                fontWeight: "bold",
              }}>
                
            </div>
          </Box>
          {items?.map((item: any, i: any) => (
            <DraggableItem
              context={context}
              key={item.id}
              index={i}
              item={item}
              handleDelete={handleDelete}
              handleClickEditOpen={handleClickEditOpen}
              onToggle={onToggleCategories}
            />
          ))}
        </Box>
        {/* Edit Dialog */}
        <CategoryForm
          editData={editData}
          handleEditClose={handleEditClose}
          open={open}
          propertyId={propertyId}
          refetch={refetchCategory}
          setOpen={setOpen}
        />
      </Grid>
    </>
  );
};

export default HotelCategories;
