import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function DrinksIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 30 34" {...props}>
      <g clipPath="url(#clip0_15_1829)">
        <path
          d="M29.5266 2.00016V0H19.5035L18.7223 5.38422H12.7155C12.5246 4.21576 12.0031 3.12389 11.2109 2.23373C10.4187 1.34358 9.38779 0.691151 8.23628 0.351225C7.08478 0.0112989 5.85928 -0.00237433 4.70025 0.311772C3.54121 0.625919 2.49552 1.25517 1.68302 2.12743C0.870523 2.99968 0.324085 4.07964 0.106329 5.24353C-0.111428 6.40743 0.00830932 7.60818 0.451817 8.70817C0.895326 9.80817 1.64466 10.7629 2.61395 11.463C3.58324 12.163 4.73326 12.5801 5.93222 12.6663L7.81084 34H22.2509L24.7737 5.38422H20.7743L21.2654 1.9975L29.5266 2.00016ZM2.03199 6.38297C2.03186 5.3301 2.42432 4.31382 3.13491 3.52695C3.84549 2.74007 4.82476 2.23735 5.8869 2.11417C6.94905 1.99099 8.02017 2.25592 8.89705 2.8587C9.77392 3.46147 10.3955 4.36015 10.6439 5.38422H5.29073L5.75279 10.6363C4.71892 10.4828 3.77514 9.96964 3.09253 9.18995C2.40993 8.41026 2.03368 7.41561 2.03199 6.38629V6.38297ZM9.67394 31.9998L7.95047 12.4405C8.64661 12.315 9.8527 12.1789 11.6173 12.2493L13.7516 17.1992L19.7294 14.7004L19.0144 13.0435C19.1493 13.0475 19.2842 13.0508 19.4191 13.0508C20.3164 13.0532 21.2107 12.9511 22.0836 12.7467L20.3905 31.9998H9.67394ZM13.8804 12.3768L16.1179 11.4411L17.0683 13.6438L14.8309 14.5795L13.8804 12.3768ZM22.2745 10.6157C21.5527 10.8568 20.0795 11.2134 18.1227 10.9723L17.1918 8.81875L13.4656 10.3766C10.76 10.111 8.88944 10.2591 7.77307 10.443L7.50325 7.38836H22.5585L22.2745 10.6157Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M10.7158 24.476L16.2255 27.8627L19.6657 22.4393L14.156 19.0526L10.7158 24.476ZM16.8643 23.0748L15.5726 25.1049L13.5125 23.8398L14.7995 21.8098L16.8643 23.0748Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M19.1861 17.4356C18.6553 17.4356 18.146 17.8958 18.1703 18.4357C18.1946 18.9756 18.6168 19.4358 19.1861 19.4358C19.7177 19.4358 20.227 18.9756 20.202 18.4357C20.177 17.8958 19.7548 17.4356 19.1861 17.4356Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M11.7635 17.3586C11.7386 16.8167 11.317 16.3585 10.7477 16.3585C10.2161 16.3585 9.70751 16.8187 9.73179 17.3586C9.75608 17.8985 10.1783 18.3587 10.7477 18.3587C11.2792 18.3587 11.7878 17.8985 11.7635 17.3586Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M12.8068 28.5381C12.276 28.5381 11.7667 28.9976 11.7917 29.5382C11.8166 30.0787 12.2375 30.5382 12.8068 30.5382C13.3384 30.5382 13.8477 30.078 13.8227 29.5382C13.7978 28.9983 13.3748 28.5381 12.8068 28.5381Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1829">
          <rect width="30" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { DrinksIcon };
