import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Input,
  Stack,
} from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import {
  Promotion,
  useAddPromotionMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";
interface prop {
  editData: Promotion | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetch: () => void;
  handleEditClose: () => void;
  open: boolean;
}
const PromotionsForm = ({
  editData,
  propertyId,
  setOpen,
  refetch,
  handleEditClose,
  open,
}: prop) => {
  const {
    register,
    handleSubmit: handleSubmitPromotion,
    formState: { isDirty },
    reset: resetPromotions,
  } = useForm<Promotion>({
    defaultValues: {
      name: editData?.name || "",
      banner_image: editData?.banner_image || "",
      description: editData?.description || "",
      end_date: editData?.start_date || "",
      start_date: editData?.end_date || "",
    },
  });

  React.useEffect(() => {
    resetPromotions(editData||
      {
        name: "",
        banner_image: "",
        description: "",
        end_date: "",
        start_date: "",
      }
      );
  }, [editData,resetPromotions]);

  const [upsertPromotion] =
    useAddPromotionMutation();

  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  const [bannerImage, setBannerImage] = React.useState<any>("");
  const [uploadImg, setUploadImg] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);
  // Add Promotion Details
  const onSubmitPromotion = async (data: Promotion) => {
    try {
      const res = await upsertPromotion({
        variables: {
          promotionInput: {
            id: editData?.id || "",
            description: data.description,
            name: data.name,
            banner_image: bannerImage ? uploadImg : data.banner_image,
            start_date: data.start_date,
            end_date: data.end_date,
            property_id: propertyId,
            is_Activated:true
          },
        },
      });
      if (res.data?.upsertPromotion?.id) {
        setOpen(false);
        setBannerImage("")
        swal({
          text: "Promotion Added Successfully",
          icon: "success",
        });
      }
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  // handle Image src
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setBannerImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadImg(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleEditClose}
    >
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Promotion" : "Add Promotion"}
          </Typography>
          <IconButton color={"inherit"} onClick={handleEditClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          component={"form"}
          onSubmit={handleSubmitPromotion(onSubmitPromotion)}
        >
          <Grid container spacing={2} mt={2}>
            {/* Promotion Input*/}
            <Grid item md={12}>
              <TextField
                fullWidth
                rows={6}
                multiline
                label="Enter Description"
                {...register("description", {
                  required: "Description is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                label="Enter Name"
                {...register("name", {
                  maxLength: 30,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                type="date"
                InputLabelProps={{ shrink: true }}
                label="Select Start Date"
                {...register("start_date", {
                  required: "Start date is required!",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                type="date"
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Select End Date"
                {...register("end_date", {
                  required: "End date is required!",
                })}
              />
            </Grid>
            <Grid item md={12} mb={2}>
              <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>

              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (editData?.banner_image || bannerImage) && (
                  <Box>
                    <img
                      alt="Banner"
                      width={"250px"}
                      src={bannerImage ? bannerImage : editData?.banner_image}
                    />

                    {bannerImage && (
                      <Box
                        component={IconButton}
                        onClick={() => setBannerImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PromotionsForm;
