import React from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetHotelServicesQuery,
  useDeleteHotelServiceMutation,
  HotelService,
  useAddServicesMutation,
} from "../../generated/graphql";

import swal from "sweetalert";

const useHotelServices = () => {
  const { getUser } = useAuthProvider();

  const property = getUser();

  const {
    data: servicesData,
    loading: loadingServices,
    refetch,
  } = useGetHotelServicesQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const [open, setOpen] = React.useState(false);
  const [upsertHotelService] = useAddServicesMutation();

  const [deleteHotelService] = useDeleteHotelServiceMutation();

  const onSave = async (data: HotelService) => {
    try {
      const res = await upsertHotelService({
        variables: {
          hotelServicesInput: {
            id: data?.id || "",
            name: data?.name,
            icon: data?.icon,
            sub_services: data?.sub_services,
            property_id: property?.id,
            sequence:data?.sequence
          },
        },
      });
      if (res.data?.upsertHotelService) {
        swal({
          text: "Service Updated Successfully",
          icon: "success",
        });
      }
      //   refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteHotelService({
        variables: {
          id: id,
          propertyID: property.id,
        },
      });

      swal({
        text: "Service Deleted Successfully",
        icon: "success",
      });
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    servicesData,
    loadingServices,
    refetch,
    setOpen,
    open,
    propertyId: property?.id,
    handleDelete,
    onSave,
  };
};

export default useHotelServices;
