import * as React from "react";
import swal from "sweetalert";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetDineInSectionsQuery,
  DineInSection,
  useDeleteDineInSectionsMutation, useAddDineInSectionsMutation
} from "../../generated/graphql";
const useSection = () => {
  const [editData, setEditData] = React.useState<DineInSection | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleClickEditOpen = (data: DineInSection | null) => {
    setEditData(data);
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // add categories
  const {
    data: Sections,
    refetch: refetchSection,
    loading: loadingCategories,
  } = useGetDineInSectionsQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });
  const [deleteSections] = useDeleteDineInSectionsMutation();

  // delete categories
  const handleDelete = async (id: string) => {
    try {
      await deleteSections({
        variables: {
          id: id,
          propertyID: property?.id,
        },
      });
      swal({
        text: "Section Deleted Successfully",
        icon: "success",
      });
      refetchSection();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };


  const [upsertSections] = useAddDineInSectionsMutation();

  const onSave = async (data: DineInSection) => {
    try {
      const res = await upsertSections({
        variables: {
          dineInSectionInput: {
            id: data?.id || "",
            name: data.name,
            icon: data.icon,
            property_id: property?.id,
            desc: data?.desc,
            sequence: data?.sequence,
            start_time: data.start_time,
            end_time: data.end_time,
          },
        },
      });
      if (res.data?.upsertDineInSection) {
        setOpen(false);
        swal({
          text: "Section updated Successfully",
          icon: "success",
        });
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    Sections: Sections?.getAllDineInSections,
    handleClickEditOpen,
    handleEditClose,
    editData,
    refetchSection,
    propertyId: property?.id,
    open,
    setOpen,
    loadingCategories,
    handleDelete,
    onSave
  };
};

export default useSection;
