import { Add, Delete, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import usePromotions from "./promotions-hook";
import Loader from "../../components/Loader";
import { EmptyImage } from "../../components/icons/EmptyImage";
import { Promotion } from "../../generated/graphql";
import PromotionsForm from "./Promotions-form";
import UiSwitch from "../../components/toggleButton/UiSwitch";

const Promotions = () => {
  const {
    promotionData,
    loadingPromotion,
    propertyId,
    handleClickEditOpen,
    handleEditClose,
    editOpen,
    editData,
    setEditOpen,
    handleDelete,
    refetch,
    onTogglePrmotion,
  } = usePromotions();

  if (loadingPromotion) {
    return <Loader />;
  }
  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Promotion Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Promotion
          </Button>
        </Grid>
      </Grid>
      {!Boolean(promotionData?.length) ? (
        <Box
          sx={{
            p: 2,
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <EmptyImage style={{ height: "50%", width: "50%" }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              No Active Promotions
            </Typography>
          </Box>
        </Box>
      ) : (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {promotionData?.map((promotion: Promotion | null, index: any) => (
            <Grid item lg={3} md={6} key={index}>
              <Card
                sx={{ borderRadius: 0, position: "relative", height: "564px" }}
              >
                <Tooltip title="Edit" placement="left" arrow>
                  <Box
                    component={Button}
                    position="absolute"
                    top={5}
                    right={5}
                    px={1}
                    minWidth="unset"
                    bgcolor={"#fff"}
                    onClick={() => handleClickEditOpen(promotion)}
                    color={"#000"}
                  >
                    <EditOutlined />
                  </Box>
                </Tooltip>
                <Tooltip title="Delete" placement="left" arrow>
                  <Box
                    component={Button}
                    position="absolute"
                    top={50}
                    right={5}
                    px={1}
                    minWidth="unset"
                    bgcolor={"#fff"}
                    onClick={() => handleDelete(promotion?.id as string)}
                    color={"#d32f2f"}
                  >
                    <Delete />
                  </Box>
                </Tooltip>
                <CardMedia
                  sx={{
                    "&.MuiCardMedia-img": {
                      height: 250,
                    },
                  }}
                  component="img"
                  image={promotion?.banner_image}
                  style={{ objectFit: "cover" }}
                />
                <CardContent>
                  <Box
                    sx={{
                      mb: 2,
                      height: 200,
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <Typography variant="h5" gutterBottom>
                      {promotion?.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {promotion?.description}
                    </Typography>
                  </Box>
                  <UiSwitch
                    checked={
                      promotion?.is_Activated != null
                        ? promotion?.is_Activated
                        : true
                    }
                    onChange={() =>
                      onTogglePrmotion(
                        promotion?.is_Activated != null
                          ? !promotion?.is_Activated
                          : false,
                        promotion?.id || ""
                      )
                    }
                    label={
                      promotion?.is_Activated === true
                        ? "Activate"
                        : "Deactivate"
                    }
                  />

                  <Stack
                    position="absolute"
                    bottom={5}
                    left={15}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Typography variant="body2" gutterBottom>
                      Start Date{" "}
                      <Chip label={promotion?.start_date} color="success" />
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      End Date{" "}
                      <Chip label={promotion?.end_date} color="error" />
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {/* Edit Dialog */}

      <PromotionsForm
        editData={editData}
        handleEditClose={handleEditClose}
        open={editOpen}
        refetch={refetch}
        setOpen={setEditOpen}
        propertyId={propertyId}
      />
    </>
  );
};

export default Promotions;
