import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import UiSwitch from "../../components/toggleButton/UiSwitch";
import useHotelInformation from "../Hotel-Information/hotel-info-hook";
import { useUpdatePropertyMutation } from "../../generated/graphql";

const Settings = () => {
  const { Details, refetchProperty } = useHotelInformation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [upsertProperty] = useUpdatePropertyMutation();

  function removeTypenameAndCopy(updatedData: any) {
    if (!updatedData || typeof updatedData !== "object") {
      return updatedData;
    }

    const result: any = Array.isArray(updatedData) ? [] : {};

    for (const key in updatedData) {
      if (key !== "__typename") {
        result[key] = removeTypenameAndCopy(updatedData[key]);
      }
    }

    return result;
  }

  const onLayoutUpdate = async (status: boolean, cardName: string) => {
    setLoading(true);
    const updatedDetails = {
      ...Details, // Copy the existing Details object
      layout: Details?.layout?.map((layout) => {
        if (layout?.cardName === cardName) {
          return { ...layout, active: !status };
        }
        return layout;
      }),
    };

    let cleanedDetails = removeTypenameAndCopy(updatedDetails);

    await upsertProperty({
      variables: {
        propertyInput: cleanedDetails,
      },
    });

    refetchProperty();
    setLoading(false);
  };

  const onPalmWaveIconUpdate = async (status: boolean) => {
    setLoading(true);
    const updatePalmIcon = {
      ...Details,
      palm_icon: !status,
    };
    let updatedDetails = removeTypenameAndCopy(updatePalmIcon);
    await upsertProperty({
      variables: {
        propertyInput: updatedDetails,
      },
    });
    refetchProperty();
    setLoading(false);
  };
  const onWhatsappIconUpdate = async (status: boolean) => {
    setLoading(true);
    const updateData = {
      ...Details,
      whatsapp_icon: !status,
    };
    let updatedDetails = removeTypenameAndCopy(updateData);
    await upsertProperty({
      variables: {
        propertyInput: updatedDetails,
      },
    });
    refetchProperty();
    setLoading(false);
  };

  return (
    <Box>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Settings
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              p={3}
              alignItems={"center"}
            >
              <Typography variant="h5">Layout Setting</Typography>
            </Box>
            <Divider />
            <CardContent sx={{ py: "0 !important", px: 3 }}>
              <List>
                {Details?.layout?.map((layout, index) => (
                  <Fragment key={index}>
                    <ListItem key={layout?.cardName}>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={8} xl={10}>
                          <UiSwitch
                            checked={layout?.active ? true : false}
                            label={layout?.cardName || ""}
                            color={(theme) => theme.palette.info.main}
                            onChange={() =>
                              onLayoutUpdate(
                                layout?.active ? true : false,
                                layout?.cardName || ""
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
                <Divider />
              </List>
            </CardContent>
          </Card>
          {loading ? (
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              position="fixed"
              height={"100%"}
              top={0}
              right={0}
              bottom={0}
              left={0}
              bgcolor="rgba(0, 0, 0, 0.1)"
            >
              <CircularProgress size={25} />
            </Box>
          ) : null}
        </Grid>
        {/* Dashboard Setting */}
        <Grid item xs={12}>
          <Card>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              p={3}
              alignItems={"center"}
            >
              <Typography variant="h5">Guest Dashboard Setting</Typography>
            </Box>
            <Divider />
            <CardContent sx={{ py: "0 !important", px: 3 }}>
              <List>
                <Fragment>
                  <ListItem>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={8} xl={10}>
                        <UiSwitch
                          checked={Details?.palm_icon ? true : false}
                          label={"Show Palm Wave"}
                          color={(theme) => theme.palette.info.main}
                          onChange={() =>
                            onPalmWaveIconUpdate(
                              Details?.palm_icon ? true : false
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Fragment>
                <Divider />
              </List>
              <List>
                <Fragment>
                  <ListItem>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={8} xl={10}>
                        <UiSwitch
                          checked={Details?.whatsapp_icon ? true : false}
                          label={"Show Whatsapp Icon"}
                          color={(theme) => theme.palette.info.main}
                          onChange={() =>
                            onWhatsappIconUpdate(
                              Details?.whatsapp_icon ? true : false
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </Fragment>
                <Divider />
              </List>
            </CardContent>
          </Card>
          {loading ? (
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              position="fixed"
              height={"100%"}
              top={0}
              right={0}
              bottom={0}
              left={0}
              bgcolor="rgba(0, 0, 0, 0.1)"
            >
              <CircularProgress size={25} />
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
