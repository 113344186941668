import { useTheme } from "@mui/material/styles";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function HotDrinksIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 34 34" {...props}>
      <g clipPath="url(#clip0_15_1847)">
        <path
          d="M25.6879 24.1898C26.1201 23.4903 26.4961 22.7575 26.8122 21.9984H29.0036C30.3289 21.9984 31.5999 21.4719 32.5371 20.5348C33.4742 19.5977 34.0007 18.3266 34.0007 17.0013C34.0007 15.676 33.4742 14.405 32.5371 13.4679C31.5999 12.5307 30.3289 12.0043 29.0036 12.0043H27.9982V10.0021H2.00016V16.0557C1.99975 18.3413 2.50571 20.5986 3.48168 22.6654C4.45765 24.7322 5.87939 26.557 7.64469 28.0088H0V31.0057C0.00140621 31.7999 0.317688 32.5611 0.87949 33.1224C1.44129 33.6837 2.20276 33.9993 2.99691 34H27.0021C27.7966 33.9993 28.5584 33.6834 29.1202 33.1217C29.6821 32.5599 29.9981 31.7982 29.999 31.0037V28.0068H22.353C23.6668 26.9264 24.7936 25.6367 25.6879 24.1898ZM27.9982 16.0557V13.9971H29.0029C29.7999 13.9971 30.5642 14.3137 31.1277 14.8772C31.6912 15.4407 32.0078 16.205 32.0078 17.002C32.0078 17.7989 31.6912 18.5632 31.1277 19.1268C30.5642 19.6903 29.7999 20.0069 29.0029 20.0069H27.4862C27.8266 18.7174 27.9986 17.3893 27.9982 16.0557ZM28.0068 30.001V31.0057C28.0058 31.2716 27.8995 31.5263 27.7112 31.7141C27.523 31.9019 27.268 32.0075 27.0021 32.0078H2.99625C2.73006 32.0075 2.47488 31.9016 2.28666 31.7133C2.09844 31.5251 1.99254 31.2699 1.99219 31.0037V29.999L28.0068 30.001ZM18.7611 28.0088H11.2379C9.05301 26.869 7.22233 25.1521 5.94489 23.0447C4.66745 20.9373 3.99213 18.52 3.99234 16.0557V11.9943H26.006V16.0557C26.0063 18.52 25.3311 20.9372 24.0538 23.0446C22.7764 25.152 20.9459 26.8689 18.7611 28.0088Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M19.5408 7.73101H21.533C21.533 7.40828 21.614 7.29605 21.8092 7.02512C22.8299 5.83777 22.4786 4.01094 21.8092 3.28246C21.614 3.01152 21.533 2.8993 21.533 2.57722H19.5408C19.5408 3.54277 19.9339 4.08797 20.1942 4.44855C20.5176 4.8284 20.5973 5.39086 20.1942 5.85969C19.9339 6.22027 19.5408 6.7648 19.5408 7.73101Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M15.5391 7.73102H17.5312C17.5312 7.40828 17.6123 7.29605 17.8075 7.02512C18.8282 5.83777 18.4769 4.01094 17.8075 3.28246C17.4841 2.90262 17.4044 2.34016 17.8075 1.87133C18.0678 1.51074 18.4609 0.966211 18.4609 0H16.4688C16.4688 0.32207 16.3877 0.434297 16.1925 0.705898C15.1718 1.89324 15.5231 3.71875 16.1925 4.44922C16.5159 4.82906 16.5956 5.39152 16.1925 5.86035C15.9322 6.22027 15.5391 6.7648 15.5391 7.73102Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M11.5381 7.73102H13.5303C13.5303 7.40828 13.6113 7.29605 13.8065 7.02512C14.8272 5.83777 14.4759 4.01094 13.8065 3.28246C13.4831 2.90262 13.4034 2.34016 13.8065 1.87133C14.0668 1.51074 14.46 0.966211 14.46 0H12.4678C12.4678 0.32207 12.3868 0.434297 12.1915 0.705898C11.1709 1.89324 11.5221 3.71875 12.1915 4.44922C12.5149 4.82906 12.5946 5.39152 12.1915 5.86035C11.9312 6.22027 11.5381 6.7648 11.5381 7.73102Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M7.5437 7.73101H9.53589C9.53589 7.40828 9.6169 7.29605 9.81214 7.02512C10.8321 5.83777 10.4815 4.01094 9.81214 3.28246C9.6169 3.01152 9.53589 2.8993 9.53589 2.57722H7.5437C7.5437 3.54277 7.93683 4.08797 8.19647 4.44855C8.52054 4.8284 8.59956 5.39086 8.19647 5.85969C7.93683 6.22027 7.5437 6.7648 7.5437 7.73101Z"
          fill={theme.palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_15_1847">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { HotDrinksIcon };
