import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function TreenutsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgIcon"
    >
      <g clipPath="url(#clip0_37_106)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#A9841C"
        />
        <path
          d="M49.9805 83.41C50.2405 82.34 50.4604 81.27 50.7704 80.22C51.8466 76.6427 54.0416 73.5049 57.0331 71.2676C60.0247 69.0302 63.6549 67.8113 67.3904 67.79C70.0904 67.79 72.7905 67.79 75.4905 67.79H76.4905C76.4914 65.0951 77.1027 62.4354 78.2787 60.0107C79.4547 57.586 81.1648 55.4591 83.2805 53.79C86.3208 51.3084 90.126 49.9552 94.0505 49.96V55.8C92.3111 55.8389 90.602 56.2626 89.0458 57.0407C87.4897 57.8187 86.1252 58.9318 85.0505 60.3C83.3583 62.4174 82.422 65.0396 82.3904 67.75H83.4504C86.3104 67.75 89.1805 67.75 92.0405 67.75C99.6505 67.88 107.14 74.38 108.34 81.9L107.01 82.42C102.746 84.0974 99.0596 86.9774 96.4005 90.71C96.2599 90.8849 96.0857 91.0298 95.8882 91.1363C95.6907 91.2428 95.4739 91.3087 95.2505 91.33C80.6372 91.33 66.0271 91.33 51.4205 91.33C51.2305 91.33 51.0305 91.33 50.7805 91.33L49.9805 87.66V83.41Z"
          fill="black"
        />
        <path
          d="M104.09 150C102.137 149.712 100.203 149.304 98.3001 148.78C95.9101 147.99 93.6201 146.89 91.3001 145.88C90.9312 145.681 90.6287 145.379 90.4301 145.01C87.1342 139.257 85.7766 132.597 86.5582 126.013C87.3397 119.429 90.2189 113.271 94.77 108.45C96.17 107 97.63 105.62 99.22 104.05L132.1 137.51C127.1 143.33 120.93 147.88 112.88 149.41C111.77 149.62 110.66 149.8 109.55 150H104.09Z"
          fill="black"
        />
        <path
          d="M134.35 81.43L138.35 77.43C143.48 81.58 146.76 93.36 138.76 101.81C140.62 103.81 142.39 105.93 144.34 107.81C152.67 116.01 151.62 128.81 142.02 135.44C141.41 135.87 140.79 136.29 140.02 136.81C126.687 123.263 113.293 109.67 99.8398 96.03C102.73 91.11 107.08 87.52 113.39 86.74C116.109 86.365 118.878 86.6439 121.468 87.5535C124.057 88.4631 126.393 89.9771 128.28 91.97C130.28 93.92 132.18 95.89 134.21 97.92C135.304 96.8467 136.171 95.5638 136.759 94.1481C137.346 92.7324 137.642 91.2128 137.63 89.68C137.67 86.6045 136.491 83.6381 134.35 81.43Z"
          fill="black"
        />
        <path
          d="M55.7305 97.27H57.1605C68.4471 97.27 79.7338 97.27 91.0204 97.27C91.3956 97.2411 91.7723 97.3013 92.1199 97.4455C92.4674 97.5897 92.776 97.8139 93.0204 98.1C93.5804 98.76 94.2704 99.32 95.0204 100.04C93.8204 101.19 92.7204 102.24 91.6304 103.31C85.706 109.022 81.9099 116.586 80.8705 124.75C80.6618 126.82 80.5683 128.9 80.5905 130.98C80.5905 132.24 80.1205 132.62 78.8505 132.28C72.9685 130.693 67.6815 127.415 63.6447 122.852C59.6078 118.289 56.9986 112.642 56.1404 106.61C55.7304 103.61 55.8505 100.47 55.7305 97.27Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_106">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { TreenutsIcon };
