import {
  alpha,
  FormControlLabel,
  styled,
  Switch,
  SwitchProps,
  Theme,
} from "@mui/material";
import React from "react";
const StyledSwitch = styled(
  ({
    customColor,
    ...props
  }: SwitchProps & { customColor?: string | ((theme: Theme) => string) }) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  )
)(({ theme, ...props }) => {
  const customColorValue =
    typeof props.customColor === "function"
      ? props.customColor(theme)
      : props.customColor || "";
  return {
    width: theme.spacing(4.2),
    height: theme.spacing(2.4),
    padding: 0,
    marginLeft: theme.spacing(2),
    marginRight: 2,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 0,
      "&.Mui-checked": {
        transform: "translateX(14px)",
        transitionDuration: "300ms",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: customColorValue
            ? alpha(customColorValue, theme.palette.action.focusOpacity)
            : theme.palette.primary.main,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": { opacity: 0.5 },
        "& .MuiSwitch-thumb": {
          backgroundColor: customColorValue || theme.palette.grey[100],
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: theme.palette.primary.light,
        border: `6px solid #b1b1b1`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": { color: theme.palette.grey[100] },
      "&.Mui-disabled + .MuiSwitch-track": { opacity: 0.7 },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: theme.spacing(2.4),
      height: theme.spacing(2.4),
      boxShadow: "none",
      backgroundColor: theme.palette.grey[500],
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.palette.grey[200],
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});
type Props = {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  color?: string | ((theme: Theme) => string);
};
export default function UiSwitch({ checked, label, onChange, color }: Props) {
  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      control={
        <StyledSwitch
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
          customColor={color}
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
}
