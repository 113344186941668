import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function EggIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgIcon"
    >
      <g clipPath="url(#clip0_130_52)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#59C5C7"
        />
        <path
          d="M50.0195 110.92C50.2195 109.28 50.3695 107.64 50.6195 106C52.6695 92.79 57.3595 80.75 66.6195 70.82C70.2162 66.7458 74.9208 63.8062 80.1595 62.36C86.3395 60.79 92.0796 62.02 97.4196 65.36C102.27 68.5055 106.367 72.6812 109.42 77.59C114.781 85.8421 118.297 95.1541 119.729 104.89C120.482 109.22 120.687 113.628 120.34 118.01C119.569 125.985 116.117 133.463 110.549 139.224C104.98 144.985 97.6238 148.689 89.6796 149.73C82.8436 150.558 75.9147 149.374 69.741 146.325C63.5672 143.275 58.4166 138.492 54.9196 132.56C52.3185 128.193 50.7073 123.308 50.1996 118.25C50.1539 117.97 50.0938 117.693 50.0195 117.42V110.92ZM85.3496 138.07C89.9873 138.074 94.5219 136.702 98.3796 134.128C102.237 131.554 105.245 127.893 107.021 123.609C108.798 119.325 109.264 114.611 108.36 110.062C107.456 105.513 105.223 101.335 101.944 98.0554C98.6647 94.7761 94.4863 92.5432 89.9375 91.6394C85.3887 90.7356 80.6742 91.2015 76.3903 92.9781C72.1063 94.7547 68.4457 97.7622 65.8716 101.62C63.2975 105.478 61.9256 110.012 61.9296 114.65C61.9611 120.852 64.4387 126.79 68.824 131.176C73.2093 135.561 79.148 138.038 85.3496 138.07Z"
          fill="black"
        />
        <path
          d="M116.8 50C117.87 50.23 118.96 50.41 120.02 50.7C125.168 52.1981 129.798 55.0997 133.39 59.08C139.26 65.2 143.18 72.48 145.99 80.4C148.705 87.7612 150.061 95.5543 149.99 103.4C149.808 111.658 146.745 119.593 141.33 125.831C135.916 132.069 128.491 136.218 120.34 137.56C120.009 137.612 119.675 137.645 119.34 137.66C120.39 135.74 121.49 133.94 122.39 132.04C125.442 125.505 126.755 118.292 126.2 111.1C125.567 99.2517 122.14 87.7211 116.2 77.45C112.11 70.45 106.94 64.34 99.87 60.08C99.34 59.76 98.8 59.47 98.26 59.18C97.72 58.89 97.17 58.63 96.54 58.31C100.85 54.15 105.64 51.14 111.54 50.19C111.86 50.14 112.17 50.05 112.48 49.98L116.8 50Z"
          fill="black"
        />
        <path
          d="M67.7901 114.64C67.7881 111.162 68.8187 107.761 70.7512 104.869C72.6838 101.976 75.4313 99.723 78.6457 98.3937C81.8601 97.0644 85.3967 96.7192 88.8075 97.4017C92.2183 98.0842 95.3498 99.7637 97.8052 102.228C100.261 104.691 101.929 107.829 102.6 111.242C103.271 114.655 102.914 118.19 101.573 121.4C100.233 124.61 97.9702 127.35 95.0714 129.272C92.1727 131.195 88.7685 132.214 85.2901 132.2C82.988 132.196 80.7092 131.739 78.5839 130.854C76.4586 129.97 74.5282 128.675 72.9032 127.044C71.2782 125.414 69.9903 123.479 69.1129 121.351C68.2356 119.222 67.7861 116.942 67.7901 114.64Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_130_52">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { EggIcon };
