import * as React from "react";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetDineInCategoriesQuery,
  DineInCategories,
  useDeleteDineInCategoriesMutation,
  useAddDineInCategoriesMutation,
} from "../../generated/graphql";
const useCategory = () => {
  const [editData, setEditData] = React.useState<DineInCategories | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleClickEditOpen = (data: DineInCategories | null) => {
    setEditData(data);
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // add categories
  const {
    data: Categories,
    refetch: refetchCategory,
    loading: loadingCategories,
  } = useGetDineInCategoriesQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });
  const [deleteCategories] = useDeleteDineInCategoriesMutation();

  // delete categories
  const handleDelete = async (id: string) => {
    try {
      await deleteCategories({
        variables: {
          id: id,
          propertyID: property?.id,
        },
      });
      swal({
        text: "Categories Deleted Successfully",
        icon: "success",
      });
      refetchCategory();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const [upsertCategories] = useAddDineInCategoriesMutation();

  const onToggleCategories = async (data: DineInCategories) => {
    if (data.is_Activated === false) {
      Swal.fire({
        text: `All items linked to ${data.name} category will also be enabled`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Accept!",
      }).then((result) => {
        if (result.isConfirmed) {
          (async () => {
            try {
              const res = await upsertCategories({
                variables: {
                  dineInCategoriesInput: {
                    id: data?.id || "",
                    name: data.name,
                    icon: data.icon,
                    property_id: property?.id,
                    desc: data?.desc,
                    sequence: data?.sequence,
                    is_Activated: true,
                  },
                },
              });
              if (res.data?.upsertDineInCategory) {
                setOpen(false);
                swal({
                  text: "Category updated Successfully",
                  icon: "success",
                });
              }
              refetchCategory();
            } catch (err) {
              swal({
                text: `${err}`,
                icon: "error",
              });
            }
          })();
        }
      });
    } else {
      try {
        const res = await upsertCategories({
          variables: {
            dineInCategoriesInput: {
              id: data?.id || "",
              name: data.name,
              icon: data.icon,
              property_id: property?.id,
              desc: data?.desc,
              sequence: data?.sequence,
              is_Activated: false,
            },
          },
        });
        if (res.data?.upsertDineInCategory) {
          setOpen(false);
          swal({
            text: "Category updated Successfully",
            icon: "success",
          });
        }
        refetchCategory();
      } catch (err) {
        swal({
          text: `${err}`,
          icon: "error",
        });
      }
    }
  };

  const onSave = async (data: DineInCategories) => {
    try {
      const res = await upsertCategories({
        variables: {
          dineInCategoriesInput: {
            id: data?.id || "",
            name: data.name,
            icon: data.icon,
            property_id: property?.id,
            desc: data?.desc,
            sequence: data?.sequence,
            is_Activated: data?.is_Activated,
          },
        },
      });
      if (res.data?.upsertDineInCategory) {
        setOpen(false);
        swal({
          text: "Category updated Successfully",
          icon: "success",
        });
      }
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  return {
    Categories: Categories?.getDineInCategories,
    handleClickEditOpen,
    handleEditClose,
    editData,
    refetchCategory,
    propertyId: property?.id,
    open,
    setOpen,
    loadingCategories,
    handleDelete,
    onSave,
    onToggleCategories,
  };
};

export default useCategory;
