import {
  EditorComposer,
  Editor,
  ToolbarPlugin,
  AlignDropdown,
  // BackgroundColorPicker,
  BoldButton,
  CodeFormatButton,
  FontFamilyDropdown,
  FontSizeDropdown,
  InsertDropdown,
  InsertLinkButton,
  ItalicButton,
  TextColorPicker,
  TextFormatDropdown,
  UnderlineButton,
  Divider,
} from 'verbum';
import { LexicalEditor } from 'lexical';
import * as React from 'react';

interface Props {
    onChange: (editorState: string, editorInstance?: LexicalEditor) => void;
    initialEditorState: (editor: LexicalEditor) => void
}
const NoteViewer = ({onChange, initialEditorState}: Props) => {
  return (
    <EditorComposer initialEditorState={initialEditorState}>
      <Editor hashtagsEnabled={true} onChange={onChange}>
        <ToolbarPlugin defaultFontSize="20px">
          <FontFamilyDropdown />
          <FontSizeDropdown />
          <Divider />
          <BoldButton />
          <ItalicButton />
          <UnderlineButton />
          <CodeFormatButton />
          <InsertLinkButton />
          <TextColorPicker />
          {/* <BackgroundColorPicker /> */}
          <TextFormatDropdown />
          <Divider />
          <InsertDropdown enablePoll={true} />
          <Divider />
          <AlignDropdown />
        </ToolbarPlugin>
      </Editor>
    </EditorComposer>
  );
};

export default NoteViewer;