import { useEffect, useState } from "react";
import { useAuthProvider } from "../../core/authContext";
import { useDeleteItineraryMutation, useGetActiveItinerariesQuery,useGetClosedItinerariesQuery } from "../../generated/graphql";
import { endOfMonth,startOfDay } from "date-fns";
import React from "react";
import swal from "sweetalert";


const useItinerary = () => {
  const { getUser } = useAuthProvider();
  const property = getUser();
  const [startDate, setStartDate] = useState<string>(startOfDay( new Date()).toISOString());
  const [endDate, setEndDate] = useState<string>(endOfMonth( new Date()).toISOString());
  const [activeList, setActiveList] = useState<any>([]);
  const [closedList, setClosedList] = useState<any>([]);

  const [editData, setEditData] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const handleClickEditOpen = (data: any | null) => {
    setEditData(data);
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  const { data:activeItineraryList, loading: loadingActiveItineraryList,refetch:refetchActiveList } = useGetActiveItinerariesQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: property?.id,
      startDate: startDate,
      endDate: endDate,
    },
    skip:!property?.id || !startDate || !endDate
  });

  const { data:closeItineraryList, loading: loadingCloseItineraryList,refetch:refetchCloseList } = useGetClosedItinerariesQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: property?.id,
      startDate: startOfDay( new Date()).toISOString(),
    },
    skip:!property?.id || !startDate
  });

  const [deleteItinerary] = useDeleteItineraryMutation();

  // Delete Itinerary
  const handleDelete = async (id: string) => {
    try {
      await deleteItinerary({
        variables: {
          id: id,
          propertyID: property?.id,
        },
      });
      swal({
        text: "Itinerary Deleted Successfully",
        icon: "success",
      });
      refetchActiveList();
      refetchCloseList();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (activeItineraryList?.getActiveItineraries || closeItineraryList?.getClosedItineraries) {
      const sortedActiveList = activeItineraryList?.getActiveItineraries
        ?.slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
        const sortedClosedList = closeItineraryList?.getClosedItineraries
        ?.slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
        setActiveList(sortedActiveList);
        setClosedList(sortedClosedList);
    } else {
      setActiveList([]);
      setClosedList([]);
    }
  }, [activeItineraryList,closeItineraryList]);


  return {
    activeList,
    closedList,
    loading:loadingActiveItineraryList || loadingCloseItineraryList,
    setStartDate,
    setEndDate,
    handleClickEditOpen,
    handleDelete,
    handleEditClose,
    editData,
    setOpen,
    refetchActiveList,
    refetchCloseList,
    open,
    propertyId: property?.id,
  };
};

export default useItinerary;
