import { Add, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import useRooms from "./rooms-hook";
import Loader from "../../components/Loader";
import RoomsForm from "./rooms-form";
import { Draggable, useDraggable,  useDraggableContext,
  moveItems, } from "react-tiny-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useAutoScroll } from "react-tiny-autoscroll";

const Item = ({
  id,
  room_type,
  sequence,
  desc,
  img,
  listeners,
  isDragging,
  handleDelete,
  handleClickEditOpen,
}: {
  id: any;
  room_type: any;
  sequence: any;
  img:any
  desc: any;
  listeners: any;
  isDragging: any;
  handleDelete: any;
  handleClickEditOpen: any;
}) => {
  const index = Number(id);
  const opacity = isDragging ? 0.5 : 1;

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, opacity, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {sequence}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {room_type}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {<img src={img} width={70} height={70} alt={room_type}></img>}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {desc}
      </div>
      <IconButton
        onClick={() => handleClickEditOpen()}
        color="info"
        aria-label="edit"
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        onClick={() => handleDelete()}
        color="error"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
      <div className="dnd-icon" {...listeners}>
        <DragIndicatorIcon />
      </div>
    </Box>
  );
};

const DraggableItem = ({
  index,
  context,
  item,
  handleDelete,
  handleClickEditOpen,
}: {
  index: any;
  context: any;
  item: any;
  handleDelete: any;
  handleClickEditOpen: any;
}) => {
  const {
    listeners,
    isDragging,
  } = useDraggable(context, index);

  return (
    <Draggable context={context} key={item.id} index={index}>
      <Item
        id={item.id}
        sequence={index + 1}
        room_type={item.room_type}
        img={item.img}
        desc={`${item.desc?.slice(0, 150)}${item.desc && item.desc.length > 150 ? "..." : ""}`}
        listeners={listeners}
        isDragging={isDragging}
        handleDelete={() => {
          handleDelete?.(item.id);
        }}
        handleClickEditOpen={() => {
          handleClickEditOpen?.(item);
        }}
      />
    </Draggable>
  );
};

const Room = () => {
  const {
    rooms,
    loadingRooms,
    handleClickEditOpen,
    handleEditClose,
    editOpen,
    editData,
    handleDeleteRoom,
    propertyId,
    setEditOpen,
    refetch,
    onSave
  } = useRooms();

  const [items, setItems] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (rooms) {
      setItems(
        rooms?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
      );
    }
  }, [rooms]);
  const onDrop = async (dragIndex: number, overIndex: number) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
    nextItems.filter((item, i) => onSave({ ...item, sequence: i }));
  };

  const context = useDraggableContext({
    onDrop,
  });
  const containerRef = React.useRef<HTMLDivElement>(null);
  useAutoScroll({ containerRef, skip: !context.isDragging });
  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Room Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Room
          </Button>
        </Grid>
      </Grid>
      {loadingRooms ? (
        <Loader />
      ) : (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Box
          ref={containerRef}
          sx={{
            border: "1px solid #ddd",
            overflowY: "auto",
            borderRadius: "4px",
            m: 2,
            width: "100%",
          }}
        >
          <Box
           sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            borderBottom: "1px solid #eee",
          }}
          style={{ height:'initial', opacity: 1, backgroundColor: "white" }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Sequence"}
            </div>
            <div
              style={{
                display: "flex",
                flex: "2 0 7%",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Room Type"}
            </div>
            <div
              style={{
                display: "flex",
                flex: "2 0 7%",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Image"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 2.5,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Description"}
            </div>
            <div     
                style={{
                display: "flex",
                flex: '0.5',
                alignItems: "center",
                fontWeight: "bold",
              }}>
                
            </div>
          </Box>
          {items?.map((item: any, i: any) => (
            <DraggableItem
              context={context}
              key={item.id}
              index={i}
              item={item}
              handleDelete={handleDeleteRoom}
              handleClickEditOpen={handleClickEditOpen}
            />
          ))}
        </Box>
          <RoomsForm
            editData={editData}
            editOpen={editOpen}
            handleEditClose={handleEditClose}
            propertyId={propertyId}
            refetch={refetch}
            setEditOpen={setEditOpen}
          />
        </Grid>
      )}
    </>
  );
};

export default Room;
