import React from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetEdibleQuery,
  Types,
  useDeleteEdibleMutation,
  DineInItem,
  useUpdateEdibleStatusMutation,
  useAddEdibleMutation,
  Diet_Type, useSyncSimphonyPosMutation,
} from "../../generated/graphql";

import swal from "sweetalert";

const useEdible = () => {
  const [editData, setEditData] = React.useState<DineInItem | null>(null);

  const [open, setOpen] = React.useState(false);

  const handleClickEditOpen = (data: DineInItem | null) => {
    setEditData(data);
    setOpen(true);
  };

  const handleEditClose = (reason:string) => {
    if(reason && reason === "backdropClick"){
      return
    }
    
    setOpen(false);
    setEditData(null);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // Delete Mutation
  const [deleteEdible] = useDeleteEdibleMutation();
  //Get All Services
  const {
    data: edibleItems,
    loading: loadingEdibleItems,
    refetch: refetchItems,
  } = useGetEdibleQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });
  // change the status
  const [upsertEdible] = useAddEdibleMutation();
  const [updateStatus] = useUpdateEdibleStatusMutation();

  const onSave = async (data: DineInItem) => {
    let extraOption = data.extraOptions?.map((extra) => {
      if (extra?.__typename) {
        delete extra?.__typename;
      }
      return extra;
    });

    try {
      await upsertEdible({
        variables: {
          dineInItemInput: {
            id: data?.id || "",
            desc: data.desc,
            img: data.img,
            name: data.name,
            price: data.price,
            isVegan: data.isVegan,
            property_id: property?.id,
            type: data.type,
            extraOptions: extraOption,
            allergens: data.allergens,
            is_Activated: data.is_Activated,
            section: data.section,
            is_recommended: data?.is_recommended,
            spice_level: data?.spice_level,
            sequence: data?.sequence,
          },
        },
      });

      swal({
        text: "Item updated Successfully",
        icon: "success",
      });
      refetchItems();
      setOpen(false);
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const onToggle = async (checked: boolean, id: string) => {
    try {
      await updateStatus({
        variables: {
          id: id,
          propertyID: property?.id,
          status: checked,
        },
      });
      swal({
        text: "status change",
        icon: "success",
      });
      refetchItems();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteEdible({
        variables: {
          id: id,
          propertyID: property.id,
        },
      });

      swal({
        text: "Item Deleted Successfully",
        icon: "success",
      });
      refetchItems();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  const ITEM_HEIGHT = 48;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

        width: 250,
      },
    },
  };

  const [syncPOSMenu] = useSyncSimphonyPosMutation();
  const handleSyncPOSMenu = async () => {
    try {
      const res = await syncPOSMenu({
        variables: {
          propertyID: property.id,
        },
      });
      if (res?.data?.syncSimphonyPOSMenuItems?.status !== 200) {
        swal({
          text: res?.data?.syncSimphonyPOSMenuItems?.message || "Contact Support",
          icon: "error",
        });
      } else {
        swal({
          text: "Synced with POS",
          icon: "success",
        });
        refetchItems();
      }

    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  }

  return {
    edibleItems: edibleItems?.getEdibles,
    categories: [
      Types.Alcohol,
      Types.Breakfast,
      Types.Desserts,
      Types.Drinks,
      Types.HotDrinks,
      Types.Mains,
      Types.Salads,
      Types.Soups,
      Types.Starters,
    ],
    Diet:[
      Diet_Type.Vegan,
      Diet_Type.NonVegan,
      Diet_Type.None
    ],
    loadingEdibleItems,

    handleClickEditOpen,
    handleEditClose,
    handleDelete,
    open,
    editData,
    setOpen,
    refetchItems,
    property,
    onToggle,
    onSave,
    handleSyncPOSMenu,
    MenuProps
  };
};

export default useEdible;
