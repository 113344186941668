import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Drawer, Button } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import NavList from './navlist';
import { Auth, Cache } from "aws-amplify";
import * as React from "react";

interface sideBarProp {
  drawerWidth: Number;
  handleDrawerToggle: Function;
  mobileOpen: boolean;
}
const Sidebar = memo(
  ({ drawerWidth, handleDrawerToggle, mobileOpen }: sideBarProp) => {
    const navigate = useNavigate();

    const Logouts = () => {
      Cache.removeItem("session");
      Auth.signOut();
      navigate("/login", { replace: true });
      window.location.reload();
    };

    return (
      <Box
        component="nav"
        sx={{ width: { sm: `${drawerWidth}` }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => handleDrawerToggle()}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: `${drawerWidth}`,
            },
          }}
        >
          <NavList />
          <Box sx={{ p: 1, pt: 1 }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={Logouts}
            >
              <Logout fontSize="small" sx={{ marginRight: '15px' }} /> Logout
            </Button>
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: `${drawerWidth}`,
            },
          }}
          open
        >
          <NavList />
          <Box sx={{ p: 1, pt: 1 }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={Logouts}
            >
              <Logout fontSize="small" sx={{ marginRight: '15px' }} /> Logout
            </Button>
          </Box>
        </Drawer>
      </Box>
    );
  },
);

export default Sidebar;